import './utils/extend-element'

import { exampleSetup } from 'prosemirror-example-setup'
import { EditorState } from 'prosemirror-state'
import React, { Component } from 'react'
import { connect, ConnectedProps } from 'react-redux'

import { showMediaPopup } from '../../cl-studio/src/app/components/media-manager/state/media-manager-popup-redux'
import { htmlToMarkdown } from '../pm/markdown/html-to-md'
import { markdownParser } from '../pm/markdown/parser'
import { markdownSchema } from '../pm/markdown/schema'
import { markdownSerializer } from '../pm/markdown/serializer'
import { getMediaIdFromUrl, getMediaIdFromVideoUrl } from '../pm/markdown/utils'
import { buildMenuItems } from '../pm/menu/menu'
import { placeholderPlugin } from '../pm/plugins/placeholder'
import Editor from './EditorView'
import { showReferencePopup } from './reference-popup/state/reference-popup-actions'
import { showRichtextPopup } from './richtext-popup/state/richtext-popup-redux'
import {
  getInfoBlocks,
  getMediaItems,
  getReferenceBlocks,
} from './utils/post-processing'

const scrollTopHistory = {}

export interface RichTextEditorData {
  value: string
  info?: any
  referenceBlocks?: Array<any>
  _mediaItems?: Array<string>
}

interface OwnProps {
  value: string
  readOnly?: boolean
  controlled?: boolean
  structure?: any
  tagsData?: any
  itemsData?: any
  onChange?: (...args: Array<any>) => any
  infoBlocks?: Array<any>
  references?: Array<any>
  referenceBlocks?: Array<any>
  updateHandler?: (value: any) => void
  theme?: string
  itemId?: number | string
  dataKey?: string
  onBlur?: (...args: Array<any>) => any
  updateItem?: (...args: Array<any>) => any
  dispatch: (...args: Array<any>) => any
}

interface State {
  editorState: ReturnType<typeof EditorState.create>
  value: string
  infoBlocks: Array<any>
  referenceBlocks: Array<any>
  selection: any
}

const connector = connect()

type Props = OwnProps & ConnectedProps<typeof connector>

const windowConfig = (window as any).__CLS__ || {}
const defaultLang = windowConfig.defaultLang

export class MDTextEditor extends Component<Props, State> {
  menu = (newProps = this.props) => {
    return buildMenuItems(markdownSchema, newProps).fullMenu
  }
  editorRef = React.createRef<Editor>()
  wrapper: HTMLDivElement | null | undefined = null
  asd = ''

  static defaultProps = {
    value: '',
    onChange: () => {
      /* no-op */
    },
    onBlur: () => {
      /* no-op */
    },
    updateHandler: () => {
      /* no-op */
    },
  }

  parseContent = (value: string) => {
    const isHtml = value.includes('</') || value.includes('<b')
    const markdown = isHtml ? htmlToMarkdown(value) : value

    return markdownParser.parse(markdown)
  }

  state = {
    editorState: EditorState.create({
      doc: this.parseContent(this.props.value),
      plugins: [
        ...exampleSetup({
          schema: markdownSchema,
          menuContent: this.menu(),
        }),
        placeholderPlugin(this.placeholderText()),
      ],
    }),
    value: this.props.value || '',
    infoBlocks: this.props.infoBlocks || [],
    referenceBlocks: this.props.referenceBlocks || [],
    selection: null,
  }

  placeholderText() {
    const defaultText = 'Content...'
    return this.props.structure?.placeholder ?? defaultText
  }

  componentDidMount() {
    this.wrapper = this.editorRef.current?.editorDOM
    this.setupTagTooltips()
    this.addInfoListeners()
    this.prepareRefBlockElements()
    this.prepareMediaElements()
    this.restoreScrollPosition()
  }

  componentDidUpdate(prevProps: Props) {
    // When switching to readonly we need to listen for a value
    // update as this will happen after mount
    if (this.props.readOnly) {
      const newValue = this.state.value !== this.props.value
      if (this.editorRef.current && newValue) {
        this.setEditorContents(this.props.value)
      }
    }

    if (prevProps.referenceBlocks !== this.props.referenceBlocks) {
      this.setState({ referenceBlocks: this.props.referenceBlocks || [] })
    }
  }

  setEditorContents = (value: string) => {
    this.setState({ value })
    if (value !== undefined) {
      this.setState({
        editorState: EditorState.create({
          doc: this.parseContent(value),
          plugins: [
            ...exampleSetup({
              schema: markdownSchema,
              menuContent: this.menu(),
            }),
            placeholderPlugin(this.placeholderText()),
          ],
        }),
      })
    }
  }

  componentWillUnmount() {
    const { readOnly, controlled } = this.props
    this.saveScrollPosition()
    if (readOnly || controlled) {
      return
    }
    this.updateHandler()
    this.removeTagTooltipListeners()
    this.removeInfoListeners()
    this.removeReferenceListeners()
    this.removeMediaListeners()
  }

  saveScrollPosition = () => {
    const scrollTop = this.wrapper?.getElementsByClassName('ProseMirror')[0]
      .scrollTop

    // Need to null check, coercing undefined, as the scrollTop might be zero.
    if (scrollTop != null) {
      const itemFieldId = this.itemFieldId()
      if (itemFieldId) {
        scrollTopHistory[itemFieldId] = scrollTop
      }
    }
  }

  /**
   * Sets the scrollTop DOM property of the ProseMirror element.
   */
  restoreScrollPosition = () => {
    const itemFieldId = this.itemFieldId()
    if (!itemFieldId) {
      return false
    }
    const scrollTop = scrollTopHistory[itemFieldId]
    if (this.wrapper && scrollTop != null) {
      // We want to set the scrollTop before the next render, whenever that might be.
      // By using rAF, we can ensure that's the case without bothering the event loop.
      requestAnimationFrame(() => {
        if (this.wrapper) {
          return (this.wrapper.getElementsByClassName(
            'ProseMirror'
          )[0].scrollTop = scrollTop)
        }
      })
    }
  }

  getScrollData = (wrapper: any) => {
    if (wrapper) {
      const { offsetHeight, scrollHeight, scrollTop } = wrapper
      let percentage = scrollTop / (scrollHeight - offsetHeight)
      percentage > 1 ? (percentage = 1) : ''
      return { scrollHeight, offsetHeight, scrollTop, percentage }
    } else {
      return {}
    }
  }

  itemFieldId() {
    const { itemId, dataKey } = this.props
    if (!itemId || !dataKey) {
      return false
    }
    return `${itemId}_${dataKey}`
  }

  addInfoListeners = () => {
    if (!this.wrapper) {
      return
    }
    const infoBlocks = Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.info-block')
    )
    infoBlocks.forEach((el) => {
      if (!el.eventListenerList) {
        el.addEventListener('click', this.infoClickHandler)
      }
    })
  }

  prepareRefBlockElements = () => {
    const referenceBlockElements = this.getReferenceBlockElements()
    const references = this.props.references || []

    referenceBlockElements.forEach((el) => {
      let title
      const authorReference = references.find(
        (reference) => reference.id === parseInt(el.dataset.refId ?? '')
      )

      if (!authorReference) {
        title = 'Parent reference has been deleted'
        el.setAttribute('class', 'ref-block error')
      } else {
        const page =
          this.state.referenceBlocks.find(
            (block) => block?.blockId === parseInt(el.dataset.blockId ?? '')
          )?.page ?? ''
        title = `${authorReference.title} ${page}`
      }

      el.setAttribute('title', title)

      if (!el.eventListenerList) {
        el.addEventListener('click', this.referenceClickHandler)
      }
    })
  }

  prepareMediaElements = () => {
    const mediaElements = this.getMediaElements()

    mediaElements.forEach((el) => {
      if (!el.eventListenerList) {
        // Double click rather than click to allow selection without opening the popup
        el.addEventListener('dblclick', this.mediaDoubleClickHandler)
      }
    })
  }

  setupTagTooltips = () => {
    const { tagsData, readOnly } = this.props
    if (readOnly || !tagsData || !this.editorRef.current || !this.wrapper) {
      return
    }
    const tags = [
      ...Array.from(
        this.wrapper.querySelectorAll<HTMLElement>('span[data-id]')
      ),
      ...Array.from(
        this.wrapper.querySelectorAll<HTMLElement>('span[data-tag-id]')
      ),
    ]
    tags.forEach((el) => {
      if (el) {
        if (!el.eventListenerList) {
          el.addEventListener('mouseover', this.makeTagTooltip)
          el.addEventListener('mouseleave', this.destroyTagTooltip)
        }
      }
    })
  }

  mediaDoubleClickHandler = (e: any) => {
    e.preventDefault()
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    this.saveScrollPosition()

    const { dispatch, itemId, updateItem } = this.props
    const { editorState } = this.state
    const editorDispatch = this.editorRef.current?.dispatchTransaction
    const el = e.target
    const align = el.getAttribute('align')
    const src = el.getAttribute('src')
    const mediaId = el.getAttribute('mediaid') || getMediaIdFromUrl(src)

    dispatch(
      showMediaPopup({
        onClose: (mediaAttrs, mediaItems) => {
          if (!mediaAttrs.src) {
            return
          }

          let mediaIdToInsert =
            mediaAttrs?.type !== 'video'
              ? getMediaIdFromUrl(mediaAttrs.src)
              : getMediaIdFromVideoUrl(mediaAttrs.src)

          if (!mediaIdToInsert) {
            mediaIdToInsert = getMediaIdFromUrl(mediaAttrs.src)
          }

          mediaAttrs.mediaId = mediaIdToInsert

          const updatedMediaAttrs =
            mediaAttrs.type === 'audio' || mediaAttrs.type === 'video'
              ? { ...mediaAttrs, src: mediaAttrs.src.split('#')[0] }
              : mediaAttrs

          editorDispatch &&
            editorDispatch(
              editorState.tr.setNodeMarkup(
                editorState.tr.selection.$head.pos - 1,
                undefined,
                updatedMediaAttrs
              )
            )

          delete mediaAttrs.src

          if (updateItem && mediaIdToInsert) {
            updateItem({
              key: 'mediaItems',
              value: {
                ...mediaItems,
                [mediaIdToInsert]: mediaAttrs,
              },
            })
          } else {
            console.error(
              'No updateItem prop passed to RTE. Implies another field has been made media friendly'
            )
          }
        },
        mediaPopupAttrs: {
          align: align ?? null,
          src,
          itemId: itemId ?? null,
          mediaId,
        },
      })
    )
  }

  infoClickHandler = (e: any) => {
    if (this.props.readOnly) {
      return
    }

    // so it doesn't trigger the blur event of the parent component
    e.preventDefault()

    if (document.activeElement instanceof HTMLElement) {
      // Stops the user from being able to continue typing in the text editor and triggering errors
      document.activeElement.blur()
    }

    const { dispatch } = this.props
    const { infoBlocks } = this.state
    const newInfoBlocks = Array.from(infoBlocks)
    const editorDispatch = this.editorRef.current?.dispatchTransaction
    const editorState = this.state.editorState
    const el = e.target
    const id = parseInt(el.getAttribute('data-info-id'))
    const block = infoBlocks.find((i) => i && i.id === id) || {}
    const oldValue = block.value || ''

    this.saveScrollPosition()
    dispatch(
      showRichtextPopup({
        value: oldValue,
        title: 'Add information or references',
        placeholder: 'Add relevant information or references...',
        checkbox: {
          label: 'Ref',
          value: block.isRef || false,
        },
        closeHandler: (value, isRef) => {
          const infoClasses = isRef ? 'info-block_ref' : 'info-block'

          // Update prosemirror state with the updated info block
          editorDispatch &&
            editorDispatch(
              editorState.tr.setNodeMarkup(
                editorState.tr.selection.$head.pos - 1,
                undefined,
                {
                  href: `${infoClasses}://${id}`,
                }
              )
            )
          const element = this.wrapper?.querySelector(`[data-info-id="${id}"]`)

          if (!element) {
            return
          }

          let className = 'info-block'

          if (isRef) {
            className += ' ref'
          }

          element.setAttribute('class', className)
          const newBlock = { id, value, isRef }
          const blockIdx = infoBlocks.findIndex((i) => i && i.id === id)
          if (blockIdx === -1) {
            return
          }

          newInfoBlocks.splice(blockIdx, 1, newBlock)

          this.setState({ infoBlocks: newInfoBlocks }, () => {
            this.updateHandler()
            this.addInfoListeners()
          })

          this.restoreScrollPosition()
        },
      })
    )

    this.restoreScrollPosition()
  }

  referenceClickHandler = (e: any) => {
    if (this.props.readOnly) {
      return
    }

    e.preventDefault()
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    const { dispatch } = this.props
    const { referenceBlocks, editorState } = this.state
    const updatedReferenceBlocks = [...referenceBlocks]

    const editorDispatch = this.editorRef.current?.dispatchTransaction
    const el = e.target
    const elRefId = parseInt(el.getAttribute('data-ref-id'))
    const elBlockId = parseInt(el.getAttribute('data-block-id'))
    const { page: elPage, additionalInfo: addInfo } = referenceBlocks.find(
      (block) => block?.blockId === elBlockId
    )

    this.saveScrollPosition()

    dispatch(
      showReferencePopup({
        id: elRefId,
        page: elPage,
        additionalInfo: addInfo,
        title: 'Update a reference',
        closeHandler: ({ refId, page, additionalInfo }) => {
          // No need to dispatch a change to the editor if things are the same
          if (
            !refId ||
            (refId === elRefId && page === elPage && additionalInfo === addInfo)
          ) {
            return
          }

          editorDispatch &&
            editorDispatch(
              editorState.tr.setNodeMarkup(
                editorState.tr.selection.$head.pos - 1,
                undefined,
                {
                  href: `ref-block://${refId}/${elBlockId}`,
                }
              )
            )
          const updatedBlock = {
            refId,
            blockId: elBlockId,
            page,
            additionalInfo,
          }

          const blockIdx = referenceBlocks.findIndex(
            (refBlock) => refBlock?.blockId === elBlockId
          )

          updatedReferenceBlocks.splice(blockIdx, 1, updatedBlock)

          this.setState({ referenceBlocks: updatedReferenceBlocks }, () => {
            this.updateHandler()
            this.prepareRefBlockElements()
          })

          this.restoreScrollPosition()
        },
      })
    )

    this.restoreScrollPosition()
  }

  removeInfoListeners = () => {
    if (!this.wrapper) {
      return
    }
    const infoBlocks = Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.info-block')
    )
    infoBlocks.forEach((el) => {
      const elClone = el.cloneNode(true)
      el.parentNode?.replaceChild(elClone, el)
    })
  }

  removeReferenceListeners = () => {
    const referenceBlocks = this.getReferenceBlockElements()
    referenceBlocks.forEach((el) => {
      const elClone = el.cloneNode(true)
      el.parentNode?.replaceChild(elClone, el)
    })
  }

  removeMediaListeners = () => {
    const mediaElements = this.getMediaElements()
    mediaElements.forEach((el) => {
      const elClone = el.cloneNode(true)
      el.parentNode?.replaceChild(elClone, el)
    })
  }

  makeTagTooltip = (e: any) => {
    if (!this.wrapper) {
      return
    }
    const { tagsData, itemsData } = this.props
    const { clientX, clientY } = e
    const {
      x: parentX,
      y: parentY,
      width: parentW,
    } = this.wrapper.getBoundingClientRect()

    const width = 120
    const height = 35
    const margin = 10

    let name
    const itemId = e.target.dataset.id
    if (itemId) {
      name = itemsData[itemId].title
    } else {
      name = tagsData?.[e.target.dataset.tagId]?.name || 'unknown'
    }

    // The ProseMirror div contains the menu bar.
    // Need to account for that height when placing the tooltip
    const MENU_HEIGHT = 30
    const scrollTop = this.wrapper.scrollTop + MENU_HEIGHT
    const tooCloseToTop = clientY - parentY - MENU_HEIGHT < height + margin
    const tooCloseToLeft = clientX - parentX - width / 2 < 5
    const tooCloseToRight = clientX + width > parentX + parentW

    const top =
      clientY -
      parentY -
      ((tooCloseToTop ? -height : height) + margin) +
      scrollTop
    const left =
      clientX -
      parentX -
      (tooCloseToLeft ? -margin : width / 2) -
      (tooCloseToRight ? margin * 4 : 0)

    const t = document.createElement('div')
    t.setAttribute('style', `top:${top}px; left:${left}px;`)
    t.className = 'tag-data-tooltip'
    t.innerText = name
    this.wrapper.appendChild(t)
  }

  removeTagTooltipListeners = () => {
    if (!this.wrapper) {
      return
    }
    const tags = [
      ...Array.from(
        this.wrapper.querySelectorAll<HTMLSpanElement>('span[data-id]')
      ),
      ...Array.from(
        this.wrapper.querySelectorAll<HTMLSpanElement>('span[data-tag-id]')
      ),
    ]

    tags.forEach((el) => {
      if (el) {
        el.removeEventListener('mouseover', this.makeTagTooltip)
        el.removeEventListener('mouseleave', this.destroyTagTooltip)
      }
    })
  }

  infoBlockElements = () => {
    if (!this.wrapper) {
      return []
    }
    return Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.info-block')
    ).filter((b) => b)
  }

  getReferenceBlockElements = () => {
    if (!this.wrapper) {
      return []
    }
    return Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.ref-block')
    ).filter((b) => b)
  }

  getMediaElements = () => {
    if (!this.wrapper) {
      return []
    }
    const mediaEls = Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.audio-block')
    ).filter((block) => block)
    const videoEls = Array.from(
      this.wrapper.querySelectorAll<HTMLElement>('.video-block')
    ).filter((block) => block)
    const imgEls = Array.from(
      this.wrapper.querySelectorAll<HTMLImageElement>('img')
    ).filter((img) => img)
    return [...mediaEls, ...videoEls, ...imgEls]
  }

  replaceQuotations = (value: string) => {
    if (defaultLang !== 'de') {
      return value
    }
    return value.replace(/["““](.*?)["””]/g, '„$1“')
  }

  updateHandler = () => {
    const { theme, updateHandler, readOnly, controlled } = this.props
    const { infoBlocks, referenceBlocks, editorState } = this.state

    const mediaIds = getMediaItems(editorState)

    if (readOnly || controlled) {
      return
    }
    const newValue = markdownSerializer.serialize(this.state.editorState.doc)
    const RTEObj: RichTextEditorData = { value: newValue }
    if (theme === 'full' || theme === 'no-media') {
      // Theme is redundant for this but we still need it as a feature flag of sorts
      RTEObj.info = getInfoBlocks(this.infoBlockElements(), infoBlocks)
      RTEObj.referenceBlocks = getReferenceBlocks(
        this.getReferenceBlockElements(),
        referenceBlocks
      )
    }

    RTEObj._mediaItems = mediaIds
    // FIXME: Here an object is passed to updateHandler while later a string is passed
    updateHandler && updateHandler(RTEObj)
  }

  destroyTagTooltip = () => {
    const el = document.querySelector('.tag-data-tooltip')
    el?.parentNode?.removeChild(el)
  }

  dispatchTransaction = (tx: any) => {
    const editorState = this.state.editorState.apply(tx)
    this.setState({ editorState })
  }

  onEditorState = (editorState: any) => {
    this.setState({ editorState })
  }

  onChange = (value: any) => {
    const { controlled, updateHandler, onChange, theme } = this.props
    const { infoBlocks, referenceBlocks, editorState } = this.state
    const newValue = value.markdown

    this.setState({
      value: newValue,
    })

    if (onChange) {
      const RTEObj: RichTextEditorData = { value: newValue }
      if (theme === 'full' || theme === 'no-media') {
        RTEObj.info = getInfoBlocks(this.infoBlockElements(), infoBlocks)
        RTEObj.referenceBlocks = getReferenceBlocks(
          this.getReferenceBlockElements(),
          referenceBlocks
        )
        RTEObj._mediaItems = getMediaItems(editorState)
      }
      onChange(RTEObj)
    }
    if (controlled && updateHandler) {
      // FIXME: Here an string is passed to updateHandler while formerly an object was passed
      updateHandler(newValue)
      return
    }
  }

  handleAddInfo = (id: any, value: any, isRef: any) => {
    const { infoBlocks } = this.state
    const block = { id, value, isRef }
    this.setState({ infoBlocks: [...infoBlocks, block] }, () => {
      this.updateHandler()
      this.addInfoListeners()
    })
  }

  handleAddReference = (reference: any) => {
    const { referenceBlocks } = this.state
    this.setState({ referenceBlocks: [...referenceBlocks, reference] }, () => {
      this.updateHandler()
      this.prepareRefBlockElements()
    })
  }

  handleAddMedia = () => {
    this.prepareMediaElements()
    this.updateHandler()
  }

  render() {
    const { editorState } = this.state
    const { dataKey, readOnly } = this.props
    // Readonly EditorViews do not listen to updates to
    // avoid conflict with react renders, we therefore
    // create a new component when values change
    const key = readOnly ? dataKey + this.state.value : dataKey
    return (
      <Editor
        ref={this.editorRef}
        key={key}
        onChange={this.onChange}
        onBlur={this.props.onBlur}
        readOnly={this.props.readOnly}
        handleAddInfo={this.handleAddInfo}
        handleAddReference={this.handleAddReference}
        handleAddMedia={this.handleAddMedia}
        editorState={editorState}
        onEditorState={this.onEditorState}
        testId={dataKey}
      />
    )
  }
}

export default connector(MDTextEditor)
