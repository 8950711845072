import { gql } from '@apollo/client'

export const itemFragment = gql`
  fragment itemFields on Item {
    id
    title
    templateId
    updatedAt
    startPoint
    endPoint
    template {
      id
      title
      parentId
    }
    partner
  }
`
