import React, { FC, useCallback, useState } from 'react'
import { connect, ConnectedProps, useDispatch } from 'react-redux'

import PartnerLogo from '../../cl-studio/src/app/views/content/components/PartnerLogo'
import { showLinkEditor as showLinkEditorAction } from './state/actions'

interface MultiItemSelectorProps {
  items: Array<any>
  icon?: string
  templateId?: number
  label?: string
  emptyLabel?: string
  query?: string
  itemId?: number
  clearHandler?: () => void
  updateHandler: (items: Array<any>) => void
}

const connector = connect(undefined, {
  showLinkEditor: showLinkEditorAction,
})
type PropsFromRedux = ConnectedProps<typeof connector>

const MultiItemSelector: FC<MultiItemSelectorProps & PropsFromRedux> = ({
  items = [],
  templateId,
  label = 'Selected items',
  emptyLabel = 'No item selected',
  query,
  icon = 'link',
  updateHandler,
  showLinkEditor,
}) => {
  const [selectedItems, setSelectedItems] = useState<
    Array<{ title: string; id: number; partner?: number }>
  >(items)
  const dispatch = useDispatch()

  const selectHandler = (id) => {
    dispatch(
      showLinkEditor({
        query: query || '',
        itemId: id ?? 0,
        templateId: templateId ?? 0,
        updateHandler: updateItem,
        clearHandler: deleteItem,
      })
    )
  }

  const updateItem = useCallback(
    (item) => {
      setSelectedItems((prevSelectedItems) => {
        const isSelected = prevSelectedItems.some(
          (selectedItem) => selectedItem.id === item.id
        )
        const newSelectedItems = isSelected
          ? prevSelectedItems.filter(
              (selectedItem) => selectedItem.id !== item.id
            )
          : [...prevSelectedItems, item]

        updateHandler(newSelectedItems)
        return newSelectedItems
      })
    },
    [updateHandler]
  )

  const clearHandler = useCallback(() => {
    setSelectedItems([])
    updateHandler([])
  }, [setSelectedItems, updateHandler])

  const deleteItem = useCallback(
    (id) => {
      setSelectedItems((prevSelectedItems) => {
        const newSelectedItems = prevSelectedItems.filter(
          (selectedItem) => selectedItem.id !== id
        )

        updateHandler(newSelectedItems)
        return newSelectedItems
      })
    },
    [setSelectedItems, updateHandler]
  )

  return (
    <nav className="item-selector item-selector--full">
      <header className="item-selector-header">
        <span>{label}</span>
        <i
          className="icon md-18"
          title="Add Item"
          onClick={() => selectHandler(null)}
        >
          playlist_add
        </i>
        <i className="icon md-18" title="Delete All" onClick={clearHandler}>
          delete
        </i>
      </header>
      <ul>
        {(!selectedItems?.length || selectedItems.length === 0) && (
          <li
            className="item-selector-item item-selector-item--empty"
            onClick={() => selectHandler(null)}
          >
            <i className="icon md-16">block</i>
            {emptyLabel}
          </li>
        )}
        {selectedItems.map(({ id, title = 'No title', partner }) => {
          return (
            <li
              key={id}
              className="item-selector-item"
              onClick={() => selectHandler(id)}
            >
              <i className="icon md-16">{icon}</i>
              <span data-id={id} className="item-selector-title">
                {title}
              </span>
              {partner && (
                <PartnerLogo
                  partnerId={partner}
                  className="item-selector-partner-logo"
                />
              )}
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default connector(MultiItemSelector)
